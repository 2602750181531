//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../..";
import router from '@/router'


class solicitudServices {
  
 
 
  solicitudActualizar(data) {
    return http.post("/inventario/solicitud/solicitudActualizar", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }


  solicitudListarPaginado(params) {  
    return http.get("/inventario/solicitud/solicitudListarPaginado", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 

  solicitudDetalleListarPaginado(params) {  
    return http.get("/inventario/solicitud/solicitudDetalleListarPaginado", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  
  
  listarTiposolicitud(params) {  
    return http.get("/inventario/solicitud/listarTiposolicitud", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  


  anularsolicitud(data) {
    return http.post("/inventario/solicitud/solicitudAnular", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  
   
}

export default new solicitudServices();